import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  return {
    title: {
      ...theme.typography.h3,
      textAlign: 'center',
      color: theme.palette.grey[700],
      marginBottom: theme.spacing(2),
    },
    paragraph: {
      ...theme.typography.h5,
      fontWeight: theme.typography.fontWeightLight,
      textIndent: '40px',
      marginBottom: theme.spacing(2),
      padding: theme.spacing(0, 5),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0),
      },
    },
  };
});

export const IntroParagraph = () => {
  const { title, paragraph } = useStyles();
  return (
    <Box>
      <Typography className={title}>Hi, I'm Trevor!</Typography>
      <Typography variant='subtitle1' className={paragraph}>
        I'm a web application developer with more than seven years of professional experience creating fast and intuitive
        web apps. I specialize in writing modern code that's highly performant and easy to deploy and scale. I value
        planning and thoughtfulness in the development cycle, I excel at verbal and written communication in a highly
        technical environment, and I strive to promote positive and productive relationships.
      </Typography>
    </Box>
  );
};
