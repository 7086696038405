import React from 'react';

import SEO from '../components/seo';
import { Box, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IntroParagraph } from '../components/intro-paragraph';
import { motion } from 'framer-motion';
import { WorkHistoryListing } from '../components/work-history-listing';
import { TextBook } from '../components/textbook';
import { VideoCourse } from '../components/video-course';

// images
import championshipPic from '../images/champions.jpg';
import murach from '../images/books/murachs-html5-and-css3.jpg';
import JsDefinitiveGuide from '../images/books/javascript-definitive-guide.jpeg';
import JsPatterns from '../images/books/javascript-patterns.png';
import jQueryBook from '../images/books/jquery-book.png';
import mongoBook from '../images/books/mongo-definitive-guide.jpg';
import nodejsVideo from '../images/video-thumbnails/node-js-video-course.jpg';
import modernReactVideo from '../images/video-thumbnails/modern-react.jpg';
import advancedReactVideo from '../images/video-thumbnails/advanced-react-1.jpg';
import mongoVideo from '../images/video-thumbnails/mongo-db.jpg';
import typescriptVideo from '../images/video-thumbnails/react-typescript.png';
import hooksVideo from '../images/video-thumbnails/hooks.png';

const useStyles = makeStyles((theme) => {
  return {
    section: {
      margin: theme.spacing(15, 0),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(5, 0, 10, 0),
      },
    },
  };
});

// personal history
// work history
// Education
// Hobbies interestes
// FAQ

const SecondPage = () => {
  const { section } = useStyles();
  return (
    <>
      <SEO title='About' />
      <motion.div key='about' initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 1 } }}>
        <Box component='section' className={section}>
          <IntroParagraph />
        </Box>

        {/* PERSONAL HISTORY */}
        <Box component='section' className={section}>
          <Typography variant='h4' paragraph>
            Personal History
          </Typography>
          <Typography variant='body1' paragraph>
            I started coding in 2015, after disliking my then job, by purchasing{' '}
            <a
              href='https://www.amazon.com/Murachs-HTML5-CSS3-Anne-Boehm/dp/1890774839'
              target='_blank'
              rel='noreferrer'
            >
              Murach's HTML 5 and CSS 3
            </a>{' '}
            from a local bookshop. For 6 months, my nights and weekends were spent learning the ins and outs of the most
            basic web technology, mastering the fundamentals of the browser at every step. I began to learn various
            libraries, including the infamous{' '}
            <a href='https://getbootstrap.com/' target='_blank' rel='noreferrer'>
              Twitter Bootstrap
            </a>
            , and this sudden burst in productivity got me very excited. I began recreating fancy looking websites to
            build my own personal portofolio. After just a few more months of learning through reading, completing
            tutorials onlines, and trying to recreate modern websites on my own, I landed my very first job with a web
            development agency in Oregon.
          </Typography>
          <Typography variant='body1' paragraph>
            I worked at that marketing agency for three years, and it was a great way to learn about web development. I
            began as one of the most amateur developers, but I quickly became one of the most trusted and capable
            developers in the department. In my first year, I was assigned many of the company's largest and highest
            priority clients. I helped to on-board and mentor new developers and was a clear leader in the department.
            In my second year, I authored my first jQuery plugin, <a href='https://multislider.trevorblackman.io'>MultiSlider</a>
            , to fulfill a company need. The MultiSlider plugin has since been used on hundreds of websites, both within
            the company, and out in the wild!
          </Typography>
          <Typography variant='body1' paragraph>
            My interests grew and I started learning backend development, using NodeJS and MongoDB. One of my first
            projects that made use of a public API was when I made a Twitter Bot to tweet on behalf of my new puppy! I
            was also using templating languages like Handlebars and PUG to create server-rendered websites, and I
            learned how to use MongoDB to persists complex data. I finally started learning how to use modern Javascript
            frameworks like React.
          </Typography>
          <Typography variant='body1' paragraph>
            After three years with the agency, I moved on to work with a cyber security company as a full time React
            developer. Since then, I've been writing custom web apps using modern web development tools like Webpack and
            Postman, writing my own modern APIs powered by Express, and deploying fast and scalable servers with NGINX
            and Docker. I may not always be working on a side project, but I always have an idea for a side project! I'm
            excited about the recent progression of web APIs and am working to improve my knowledge of Progressive Web
            Apps and offline web support.
          </Typography>
        </Box>

        {/* WORK HISTORY */}
        <Box component='section' className={section}>
          <Typography variant='h4' paragraph>
            Work History
          </Typography>
          <WorkHistoryListing
            companyName='Eclypses'
            companyURL='https://www.eclypses.com'
            companyLocation='Colorado Springs, CO'
            roles='Senior Web Developer / Web Developer II'
            dates='May 2018 - Current'
            responsibilities={[
              'Lead front-end engineer and Javascript solutions architect',
              'Created fast, modern, and intuitive user interfaces on the web',
              'Developed robust web APIs using Node, Express, and MongoDB',
              'Managed deployment processes and assisted in CI/CD pipeline setup',
              'Authored comprehensive developer documentation on multiple projects',
            ]}
          />
          <WorkHistoryListing
            companyName='Dealer Spike, LLC.'
            companyURL='https://www.dealerspike.com/'
            companyLocation='Lake Oswego, OR'
            roles='Frontend Developer / VIP Support / Account Manager'
            dates='Aug 2015 - May 2018'
            responsibilities={[
              'Developed and standardized jQuery plugins used regularly on client sites',
              'Managed communications and site updates of VIP clients for 2 years',
              'Completed many custom billable projects for clients of all levels',
              'Spoke with clients daily to better understand their needs',
              'Worked closely with development team to resolve issues and publish new features',
            ]}
          />
        </Box>

        {/* Education */}
        <Box component='section' className={section}>
          <Typography variant='h4' paragraph>
            Formal Education
          </Typography>
          <Typography variant='body1'>
            Although my college education doesn't consist of very many technical classes, I attribute much of who I am
            today to those years. At college I learned the importance of patience and diligence, I refined my ability to
            effectively communicate with colleagues, and I saw first-hand the results of a lot of hard work over time.
            For me, a non-technical college degree was still an invaluable experience in my path to becoming a
            professional web developer.
          </Typography>
          <br />
          <Typography variant='h5'>Western Colorado State University</Typography>
          <Typography variant='body1' component='span' style={{ marginRight: '40px' }}>
            Gunnison, CO
          </Typography>
          <Typography variant='body1' component='span'>
            May, 2013
          </Typography>
          <ul>
            <li>B.A., Biology (Cellular Emphasis)</li>
            <li>B.A., Excersize and Sport Science (Clinical Kinesiology Emphasis)</li>
          </ul>
          <br />
          <Grid container direction='row-reverse' alignItems='flex-start' spacing={4}>
            <Grid item xs={12} md={6}>
              <img
                style={{ width: '100%', display: 'block' }}
                src={championshipPic}
                alt='2011 NCAA Division II Cross Country National Champions'
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant='h5' paragraph>
                2011 XC National Champions
              </Typography>
              <Typography variant='body1'>
                While completing my two bachelors degrees, I was fortunate enough to lead my fellow teammates to a
                national championship as the captain of the Cross Country and Track teams. My collegiate athletic career
                resulted in a national team title and two All-American acolades, and taught me perseverance, commitment,
                optimism, and leadership. These are traits that I try to exhibit in my daily behavior and demeanor.
              </Typography>
            </Grid>
          </Grid>
        </Box>

        {/* Education */}
        <Box component='section' className={section}>
          <Typography variant='h4' paragraph>
            Technical Education
          </Typography>
          <Typography variant='body1'>
            The resources below have been absolutely critical in helping me build and strengthen fundamental development
            skills. I've only listed the most essential resources that I have completed and understood thoroughly. I
            admit that I don't know everything, but I am proud to have successfully tought myself, I believe in my
            ability to demonstrate modern development techniques, and I'm confident in my ability to learn new skills
            and technologies in the future.
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextBook
              title="Murach's HTML5 and CSS3 (3rd Edition)"
              url='https://www.murach.com/shop/murachs-html5-and-css3-3rd-edition-detail'
              imgSrc={murach}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextBook title='Javascript &amp; jQuery' url='http://javascriptbook.com/' imgSrc={jQueryBook} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextBook
              title='JavaScript - The Definitive Guide, 5th Edition'
              url='https://www.oreilly.com/library/view/javascript-the-definitive/0596101996/'
              imgSrc={JsDefinitiveGuide}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextBook
              title='JavaScript Patterns'
              url='https://www.oreilly.com/library/view/javascript-patterns/9781449399115/'
              imgSrc={JsPatterns}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextBook
              title='MongoDB: The Definitive Guide, 2nd Edition'
              url='https://www.oreilly.com/library/view/mongodb-the-definitive/9781449344795/'
              imgSrc={mongoBook}
            />
          </Grid>
        </Grid>
        <VideoCourse
          title='The Complete Node.js Developer Course'
          url='https://www.udemy.com/course/the-complete-node-js-developer-course/'
          imgSrc={nodejsVideo}
          notes={[
            'Introduction to the NodeJS runtime, ecosystem, and project layout.',
            'Creation of REST API using CRUD opperations.',
            'Home-grown user authentication and app security.',
            'Exploration of common application architecture using ExpressJS',
            'Introduction to Github and Git best practices',
          ]}
        />
        <VideoCourse
          title='Modern React with Redux'
          url='https://www.udemy.com/course/react-redux/'
          imgSrc={modernReactVideo}
          notes={[
            'Introduction to modern React and best coding practices.',
            'Presentation of state management in front-end apps.',
            'Redux patterns, flow, and complex state management.',
          ]}
        />
        <VideoCourse
          title='Advanced React and Redux'
          url='https://www.udemy.com/course/react-redux-tutorial/'
          imgSrc={advancedReactVideo}
          notes={[
            'Introduction to testing React and Redux code.',
            'Explaination of pros and cons of Higher Order Components.',
            'Using modern React Hooks and functional components.',
            'Using advanced app deployment techniques.',
          ]}
        />
        <VideoCourse
          title='The Complete Developers Guide to MongoDB'
          url='https://www.udemy.com/course/the-complete-developers-guide-to-mongodb/'
          imgSrc={mongoVideo}
          notes={[
            'Getting started with MongoDB locally.',
            'CRUD opperations on documents within MongoDB.',
            'How to manipulate and agreggate data.',
            'Advanced query techniques and collection indexing.',
          ]}
        />
        <VideoCourse
          title='React &amp; Typescript for Everyone'
          url='https://www.leveluptutorials.com/tutorials/react-and-typescript-for-everyone'
          imgSrc={typescriptVideo}
          notes={[
            'Introduction to Typescript and the problems it solves.',
            'Using Typescript effectively in React.',
            'How and when to use Types and Interfaces',
            'Explaination of Union and Generic types.',
          ]}
        />
        <VideoCourse
          title='Custom React Hooks'
          url='https://www.leveluptutorials.com/tutorials/custom-react-hooks'
          imgSrc={hooksVideo}
          notes={[
            'Introduction to creating basic React hooks.',
            'Creating custom hooks around component mount and unmount.',
            'How to build custom hooks around DOM APIs and events.',
            'Exploration of asyncronous network custom hooks.',
          ]}
        />
      </motion.div>
    </>
  );
};

export default SecondPage;
