import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';

export const WorkHistoryListing = ({
  companyName = 'Unknown Company, LLC',
  companyLocation = 'Boulder, CO',
  companyURL = 'https://www.google.com',
  roles = 'Best Title / Middle / Lowest Title',
  dates = 'Aug 1989 - June 1991',
  responsibilities = ['Steal underpants', '???', 'Make mad Profit!'],
}) => {
  return (
    <Box mb={5}>
      <Grid container>
        <Grid item md={6} xs={12} style={{ paddingRight: '20px' }}>
          <Typography variant='h5'>{companyName}</Typography>
          <Typography variant='subtitle2'>{roles}</Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography variant='body1'>{companyLocation}</Typography>
          <Typography variant='body1'>{dates}</Typography>
        </Grid>
      </Grid>
      <ul>
        {responsibilities.map((item, index) => (
          <li key={index}>
            <Typography variant='body1'>{item}</Typography>
          </li>
        ))}
      </ul>
    </Box>
  );
};
