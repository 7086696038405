import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  return {
    img: {
      maxHeight: '200px',
      width: 'auto',
      display: 'block',
      margin: '0 auto 10px',
      boxShadow: '0 0 8px rgba(0,0,0,0.5)',
    },
  };
});

export const TextBook = ({
  title = 'Book Title',
  url = 'https://www.google.com',
  imgSrc = 'https://media.istockphoto.com/photos/golden-retriever-and-british-short-hair-cat-picture-id1027475322',
}) => {
  const { img } = useStyles();

  return (
    <Box>
      <img className={img} src={imgSrc} alt={title} />
      <Typography variant='subtitle1'>
        <a href={url} target='_blank' rel='noreferrer' style={{ display: 'block', width: '100%', textAlign: 'center' }}>
          {title}
        </a>
      </Typography>
    </Box>
  );
};
