import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      margin: theme.spacing(8, 0),
    },
    img: {
      display: 'block',
      width: '275px',
      margin: theme.spacing(0, 2, 1, 0),
      boxShadow: '0 0 8px rgba(0,0,0,0.5)',
    },
    link: {
      textDecoration: 'none',
    },
    list: {
      margin: 0,
      paddingLeft: '20px',
    },
  };
});

export const VideoCourse = ({
  title = 'Video course Title',
  url = 'https://www.google.com',
  imgSrc = 'https://media.istockphoto.com/photos/golden-retriever-and-british-short-hair-cat-picture-id1027475322',
  notes = [
    'This is bullet point number 1.',
    'This is bullet point number 2.',
    'This is bullet point number 3.',
    'This is bullet point number 4.',
  ],
}) => {
  const { root, img, link, list } = useStyles();
  return (
    <Grid container className={root}>
      <Grid item>
        <img src={imgSrc} alt={title} className={img} />
      </Grid>
      <Grid item>
        <Typography variant='subtitle1'>
          <a href={url} target='_blank' rel='noreferrer' className={link}>
            {title}
          </a>
        </Typography>
        <ul className={list}>
          {notes.map((item, index) => (
            <li key={index}>
              <Typography variant='body1'>{item}</Typography>
            </li>
          ))}
        </ul>
      </Grid>
    </Grid>
  );
};
